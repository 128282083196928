.aside-nav {
	position: fixed;
	right: 10px;
	top: 350px;
	width: 60px;
	height: 60px;
	-webkit-filter: url(#goo);
	filter: url(#goo);
	-ms-user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	user-select: none;
    z-index: 1000;
	opacity: .75}
.aside-nav.no-filter {
	-webkit-filter: none;
	filter: none}
.aside-nav .aside-menu {
	position: absolute;
	width: 40px;
	height: 40px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	background: #487ff5;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	text-align: center;
	line-height: 40px;
	color: #fff;
	font-size: 10px;
	z-index: 1000;
	cursor: move;
	overflow: hidden;
}
.aside-nav .menu-item {
	position: absolute;
	width: 32px;
	height: 32px;
	background-color: #487ff5;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	line-height: 16px;
	text-align: center;
	-webkit-border-radius: 20%;
	border-radius: 20%;
	text-decoration: none;
	color: #fff;
	-webkit-transition: background .5s, -webkit-transform .6s;
	transition: background .5s, -webkit-transform .6s;
	-moz-transition: transform .6s, background .5s, -moz-transform .6s;
	transition: transform .6s, background .5s;
	transition: transform .6s, background .5s, -webkit-transform .6s, -moz-transform .6s;
	font-size: 12px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box}
.aside-nav .menu-item:hover {

	background: #A9C734;
}
.aside-nav .menu-line {

    line-height: 20px;
	padding-top: 10px;
}
.aside-nav:hover {

    opacity: 1;
}
.aside-nav:hover .aside-menu {
	-webkit-animation: jello 1s;
	-moz-animation: jello 1s;
	animation: jello 1s
}
.aside-nav:hover .menu-first {
	-webkit-transform: translate3d(0, -135%, 0);
	-moz-transform: translate3d(0, -135%, 0);
	transform: translate3d(0, -135%, 0)
}
.aside-nav:hover .menu-second {
	-webkit-transform: translate3d(-120%, -70%, 0);
	-moz-transform: translate3d(-120%, -70%, 0);
	transform: translate3d(-120%, -70%, 0)
}
.aside-nav:hover .menu-third {
	-webkit-transform: translate3d(-120%, 70%, 0);
	-moz-transform: translate3d(-120%, 70%, 0);
	transform: translate3d(-120%, 70%, 0)
}
.aside-nav:hover .menu-fourth {
	-webkit-transform: translate3d(0, 135%, 0);
	-moz-transform: translate3d(0, 135%, 0);
	transform: translate3d(0, 135%, 0)
}
.aside-nav:hover .menu-fifth {
	-webkit-transform: translate3d(0, -240%, 0);
	-moz-transform: translate3d(0, -240%, 0);
	transform: translate3d(0, -240%, 0)
}
.animated {
	-webkit-animation-duration: 1s;
	-moz-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	-moz-animation-fill-mode: both;
	animation-fill-mode: both
}
@-webkit-keyframes bounceInUp {
	from, 60%, 75%, 90%, to {
		-webkit-animation-timing-function:cubic-bezier(0.215, .61, .355, 1);
		animation-timing-function:cubic-bezier(0.215, .61, .355, 1)
	}
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 800px, 0);
		transform: translate3d(0, 800px, 0)
	}
	60% {
		opacity:1;
		-webkit-transform:translate3d(0, -20px, 0);
		transform:translate3d(0, -20px, 0)
	}
	75% {
		-webkit-transform:translate3d(0, 10px, 0);
		transform:translate3d(0, 10px, 0)
	}
	90% {
		-webkit-transform:translate3d(0, -5px, 0);
		transform:translate3d(0, -5px, 0)
	}
	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0)
	}
}
@-moz-keyframes bounceInUp {
	from, 60%, 75%, 90%, to {
		-moz-animation-timing-function:cubic-bezier(0.215, .61, .355, 1);
		animation-timing-function:cubic-bezier(0.215, .61, .355, 1)
	}
	from {
		opacity: 0;
		-moz-transform: translate3d(0, 800px, 0);
		transform: translate3d(0, 800px, 0)
	}
	60% {
		opacity:1;
		-moz-transform:translate3d(0, -20px, 0);
		transform:translate3d(0, -20px, 0)
	}
	75% {
		-moz-transform:translate3d(0, 10px, 0);
		transform:translate3d(0, 10px, 0)
	}
	90% {
		-moz-transform:translate3d(0, -5px, 0);
		transform:translate3d(0, -5px, 0)
	}
	to {
		-moz-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0)
	}
}
@keyframes bounceInUp {
	from, 60%, 75%, 90%, to {
		-webkit-animation-timing-function:cubic-bezier(0.215, .61, .355, 1);
		-moz-animation-timing-function:cubic-bezier(0.215, .61, .355, 1);
		animation-timing-function:cubic-bezier(0.215, .61, .355, 1)
	}
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 800px, 0);
		-moz-transform: translate3d(0, 800px, 0);
		transform: translate3d(0, 800px, 0)
	}
	60% {
		opacity:1;
		-webkit-transform:translate3d(0, -20px, 0);
		-moz-transform:translate3d(0, -20px, 0);
		transform:translate3d(0, -20px, 0)
	}
	75% {
		-webkit-transform:translate3d(0, 10px, 0);
		-moz-transform:translate3d(0, 10px, 0);
		transform:translate3d(0, 10px, 0)
	}
	90% {
		-webkit-transform:translate3d(0, -5px, 0);
		-moz-transform:translate3d(0, -5px, 0);
		transform:translate3d(0, -5px, 0)
	}
	to {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0)
	}
}
.bounceInUp {
	-webkit-animation-name: bounceInUp;
	-moz-animation-name: bounceInUp;
	animation-name: bounceInUp;
	-webkit-animation-delay: 1s;
	-moz-animation-delay: 1s;
	animation-delay: 1s
}
/* @media screen and (max-width:640px) {
	.aside-nav {
		display: none!important
	}
} */
@media screen and (min-width:641px) and (max-width:1367px) {
	.aside-nav {
		top: 120px
	}
}
