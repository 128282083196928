div.overlay_pop_window {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;

    background-color: hsla(0, 0%, 13%, 0.8);
    z-index: 105;

    pointer-events: none;
    opacity: 0;
    visibility: hidden;

    transition: all 0.2s ease-in;
    overflow: hidden;

    .overlay-content {
        transform: translateY(20px);
        transition: transform 0.2s ease-in;
        z-index: 102;
    }

    &.show {
        opacity: 1;
        pointer-events: all;
        visibility: visible;
        transition: opacity 0.2s ease-out;

        .overlay-content {
            transform: translateY(0);
            transition-timing-function: ease-out;
        }
    }
}

#pop_window{
    .exit {
        font-size: 1.5rem;
        font-weight: 600;
        background-color: transparent;
        border: none;
        position: absolute;
        right: 8px;
        z-index: 1;
        color: hsl(0, 0%, 67%);
    }

    .overlay-content {
        width: calc(100% - 20px);;
        height: 600px;
        border-radius: 4px;
    }
}